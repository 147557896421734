import MDBox from "components/MDBox";
import { Dialog, DialogContent, Grid } from "@mui/material";
import { Card, DialogActions, TableCell } from "@material-ui/core";
import client from "ApiClient";
import MDTypography from "components/MDTypography";
import { useEffect, useState } from "react";
import moment from "moment";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

/* eslint-disable */
export default function Subscription(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [plan, setPlan] = useState(props.plan);
  const { openModal, closeModal } = props;
  const [openSB, setOpenSB] = useState(false);
  const [colorSB, setColorSB] = useState("success");
  const [iconSB, setIconSB] = useState("success");
  const [titleSB, setTitleSB] = useState("Success");
  const [contentSB, setContentSB] = useState("Subscription updated successfully");

  const oldRender = TableCell.render;
  TableCell.render = function (...args) {
    const [props, ...otherArgs] = args;
    if (typeof props === "object" && props && "isEmpty" in props) {
      const { isEmpty, ...propsWithoutEmpty } = props;
      return oldRender.apply(this, [propsWithoutEmpty, ...otherArgs]);
    } else {
      return oldRender.apply(this, args);
    }
  };

  const options = {
    method: "GET",
    url: "reports",
  };

  const planInformation = async () => {
    if (props.plan.user_id == undefined) return;

    options.url = `reports/planInformation?user_id=${props.plan.user_id}`;

    await client
      .request(options)
      .then((response) => {
        setPlan(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const pause = () => {
    const options = {
      method: "GET",
      url: `users/pauseSubscription?user_id=${props.plan.user_id}`,
      headers: {
        "content-type": "application/json",
      },
    };

    client
      .request(options)
      .then((response) => {
        setOpenSB(true);
        setContentSB("Subscription paused successfully");
        planInformation();
        props.onDone();
        props.closeModal();
      })
      .catch(() => {
        // setErrorSB(true);
      });
  };

  const cancel = () => {
    const options = {
      method: "GET",
      url: `users/cancelSubscription?user_id=${props.plan.user_id}`,
      headers: {
        "content-type": "application/json",
      },
    };

    client
      .request(options)
      .then((response) => {
        setOpenSB(true);
        setContentSB("Subscription cancelled successfully");
        planInformation();
        props.onDone();
        props.closeModal();
      })
      .catch(() => {
        // setErrorSB(true);
      });
  };

  useEffect(() => {
    planInformation();
  }, [props.plan]);

  return (
    <Dialog
      open={openModal}
      onClose={() => props.onClose}
      maxWidth="xl"
      sx={{
        //You can copy the code below in your theme
        background: "#F4F0F700",
        "& .MuiPaper-root": {
          background: "#F4F0F7FF",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "transparent", // Try to remove this to see the result
        },
      }}
    >
      <MDSnackbar
        color={colorSB}
        icon={iconSB}
        title={titleSB}
        content={contentSB}
        dateTime={""}
        open={openSB}
        onClose={() => setOpenSB(false)}
        close={() => setOpenSB(false)}
        bgWhite
      />
      <DialogContent>
        <MDBox pb={3} pt={2}>
          <Grid container spacing={6}>
            {plan && (
              <Grid item xs={12} md={12} lg={12} p={1} mb={2} pb={2} style={{ width: "100%" }}>
                <Card style={{ padding: 10, backgroundColor: "#FFFFFF", width: 600 }}>
                  <Grid
                    justify="flex-end"
                    pb={2}
                    px={2}
                    pt={2}
                    style={{ width: "100%", textAlign: "right" }}
                  >
                    {props.plan.status != "Paused" && (
                      <MDButton
                        disabled={isLoading}
                        variant="outlined"
                        color="warning"
                        sx={{ marginX: 2 }}
                        onClick={() => pause()}
                      >
                        Pause
                      </MDButton>
                    )}
                    <MDButton
                      disabled={isLoading}
                      variant="outlined"
                      color="error"
                      onClick={() => cancel()}
                    >
                      Cancel
                    </MDButton>
                  </Grid>
                  <MDBox pb={2} px={2} pt={2}>
                    <MDTypography variant="h4" fontWeight="medium" textTransform="capitalize">
                      Subscription plan
                    </MDTypography>
                    <MDTypography variant="subtitle2" color="text" fontWeight="regular">
                      Cost ${plan.cost / 100} / {plan.frequency}
                    </MDTypography>
                  </MDBox>
                  <MDBox pb={2} px={2}>
                    <MDTypography variant="subtitle" color="text" fontWeight="regular">
                      Your plan will renew at {moment(plan.nextBillingDate).format("MMM Do YY")}
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
            )}
          </Grid>
        </MDBox>
      </DialogContent>
      <DialogActions style={{ color: "white", backgroundColor: "white" }}>
        <MDButton onClick={closeModal} disabled={isLoading} variant="outlined" color="secondary">
          Close
        </MDButton>
      </DialogActions>
    </Dialog>
  );
}
